import apiCall from 'app/apiCalls/order';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import OrderForm from './forms/OrderForm';
import InvoiceItemDetail from './invoice';

export const OrderListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: "Search by invoice number, username or email" }}
    filterOptions={{ filters: ["date", "users"] }}
    title="All Sales Orders"
    apiCall={apiCall}
    createUrl="/app/orders/create"
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    {...props}
  />
);

export const OrderDetailPage = props => (
  <DetailPage
    title="Sale Detail"
    apiCall={apiCall}
    listUrl={`/app/orders`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const OrderInvoicePage = props => (
  <DetailPage
    title="Sale Invoice"
    apiCall={apiCall}
    listUrl={`/app/orders`}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);

export const OrderEditPage = props => (
  <EditPage
    title="Sale Edit"
    apiCall={apiCall}
    listUrl={`/app/orders`}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);

export const OrderCreatePage = props => (
  <CreatePage
    title="Sale Create"
    apiCall={apiCall}
    listUrl={`/app/orders`}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);
