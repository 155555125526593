import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_CUSTOMER_LIST_API = 'DASHBOARD/FETCH_CUSTOMER_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_CUSTOMER_LIST_API, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/admin/v1/customers`,
    params: { ...params, account_type: 'customer' },
  }),
  method: 'GET',
}));

export default apiCall;
