import { Button, Tag } from 'antd';
import { orderStatusColors, orderStatusLabels } from 'app/constants/order';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';

export const columnsGenerator = params => [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    render: value => formatDate(value),
  },
  {
    title: 'Invoice Number',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: 'Debtor Name',
    dataIndex: 'user',
    render: user => {
      if (!user) return null;
      return user.name;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Modifed Date',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return (
        <Tag color={orderStatusColors[status]}>{orderStatusLabels[status]}</Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/app/orders/${record.id}`}>View</Link>
        </Button>
        <Button
          shape="round"
          disabled={record.status == 1002 || record == 1003}
        >
          <Link to={`/app/orders/${record.id}/edit`}>Edit</Link>
        </Button>
        <Button shape="round">
          <Link to={`/app/orders/${record.id}/invoice`}>Invoice</Link>
        </Button>
      </Button.Group>
    ),
  },
];
