import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_EXPENSE_TYPE_DETAIL_API_ID =
  'DASHBOARD/FETCH_EXPENSE_TYPE_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_EXPENSE_TYPE_DETAIL_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/expense-types/${id}`,
    method: 'GET',
  })
);

export default apiCall;
