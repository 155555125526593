import { Button, Form, Input, Select, Space, notification } from 'antd';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { paymentMethodsOptions } from 'app/constants/paymentMethod';
import { ensureDateFields, FormattedError } from 'utils/formData';
import { modalFormLayout, tailFormItemLayout } from 'utils/formConfig';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import paymentTxItemApiCall from 'app/apiCalls/paymentTxItem';
import paymentTxApiCall from 'app/apiCalls/paymentTx';

const usePaymentTxItemQuery = id => {
  return useQuery({
    queryKey: [paymentTxItemApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return paymentTxItemApiCall.detail.queryFn({ id });
      }

      return Promise.resolve('');
    },
  });
};

const PaymentTxItemForm = ({ form, name, closeModal, params }) => {
  const queryClient = useQueryClient();
  const { transactionId, id } = params;

  const { data: initialValues = {} } = usePaymentTxItemQuery(id);

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: paymentTxItemApiCall.delete.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([paymentTxApiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: createItem } = useMutation({
    mutationFn: paymentTxItemApiCall.create.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([paymentTxApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem, isLoading: isUpdating } = useMutation({
    mutationFn: paymentTxItemApiCall.edit.queryFn,

    onSuccess: () => {
      queryClient.invalidateQueries([paymentTxApiCall.detail.queryKey]);
      queryClient.invalidateQueries([paymentTxItemApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  useEffect(() => {
    const values = ensureDateFields(initialValues);

    if (values.user && values.user.id) {
      form.setFieldsValue({
        ...values,
        user: values.user.id,
      });
    } else {
      form.setFieldsValue(values);
    }
  }, [form, initialValues]);

  return (
    <Form
      {...modalFormLayout}
      form={form}
      name={name || 'payment_tx_item_form'}
      onFinish={values => {
        const payload = { ...params, ...values, transaction: transactionId };

        if (id) {
          updateItem({ id, payload });
        } else {
          createItem({ payload });
        }

        closeModal();
        form.resetFields();
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="particular" label="Particular">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="amount" label="Amount">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>
      <Form.Item name="payment_method" label="Payment Mode">
        <Select>
          {paymentMethodsOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="tax" label="Tax">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          {initialValues.id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id });
                  closeModal();
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PaymentTxItemForm;
