import { Button, Card, Descriptions, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <Card
      title={<Title level={3}>Expense Type Detail</Title>}
      extra={
        <Space>
          <Button>
            <Link to={`/app/expense-types/${item.id}/edit`}>Edit</Link>
          </Button>
        </Space>
      }
    >
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Expense ID">
          {item.code}
        </Descriptions.Item>
        <Descriptions.Item label="Expense Name (English)">
          {item.name}
        </Descriptions.Item>
        <Descriptions.Item label="Expense Name (Malay)">
          {item.name_ms}
        </Descriptions.Item>
        <Descriptions.Item label="Expense Name (Chinese)">
          {item.name_zh}
        </Descriptions.Item>

        <Descriptions.Item label="Ordering">
          {item.ordering}
        </Descriptions.Item>

        <Descriptions.Item label="Admin Note">
          {item.admin_notes}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ItemDetail;

