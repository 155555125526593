import { useParams } from 'react-router-dom';

import apiCall from 'app/apiCalls/supplier';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const SupplierListPage = (props, { params }) => {
  return (
    <ListPage
      searchOptions={{ tooltip: "Enter name, email or phone number" }}
      title="All Suppliers"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="items"
      createUrl={`/app/suppliers/create`}
      {...props}
    />
  );
};

export const SupplierEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="Suppliers"
      apiCall={apiCall}
      listUrl={`/app/suppliers/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName=""
      {...props}
    />
  );
};

export const SupplierDetailPage = props => {
  return (
    <DetailPage
      title="Suppliers"
      apiCall={apiCall}
      listUrl={`/app/suppliers`}
      ItemDetail={ItemDetail}
      resourceName=""
      {...props}
    />
  );
};

export const SupplierCreatePage = props => (
  <CreatePage
    title="Suppliers"
    apiCall={apiCall}
    listUrl={`/app/suppliers`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
