import apiCall from 'app/apiCalls/journal';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const JournalEntryListPage = (props, { params }) => {
  return (
    <ListPage
      title="All Journal Entries"
      searchOptions={{ tooltip: false }}
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      filterOptions={{ filters: ["date", "branches"] }}
      {...props}
    />
  );
};

export const JournalEntryDetailPage = props => {
  return (
    <DetailPage
      title="Journal Entries"
      apiCall={apiCall}
      listUrl={`/app/journal-entries`}
      ItemDetail={ItemDetail}
      resourceName=""
      {...props}
    />
  );
};
