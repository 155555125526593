import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_SUPPLIER_LIST_API = 'DASHBOARD/FETCH_SUPPLIER_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_SUPPLIER_LIST_API, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/admin/v1/users`,
    params: params,
  }),
  method: 'GET',
}));

export default apiCall;
