import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';
import { map } from 'lodash/fp';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import { convertDateFormData, ensureDateTimeFields } from 'utils/formData';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { usePostApi } from 'common/reduxutils';
import DebounceSelect from 'common/ui/DebouceSelect';
import apiCall from 'app/apiCalls/inventoryTracking';
import variantApiCall from 'app/apiCalls/productVariant';

const options = [
  {
    value: 'purchase',
    label: 'Purchase',
  },
  // {
  //   value: 'sale',
  //   label: 'Sale',
  // },
];

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const { product_id, inventory_type } = useParams();
  const { product } = initialValues;

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    const values = ensureDateTimeFields({ inventory_type, ...initialValues }, [
      'date',
    ]);
    if (values.product_variant && values.product_variant.id) {
      form.setFieldsValue({
        ...values,
        product_variant: values.product_variant.id,
      });
    } else {
      form.setFieldsValue(values);
    }
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'inventory_form'}
      onFinish={values => {
        const payload = {
          ...values,
          product: product ? product.id : product_id,
        };
        return onFinish(convertDateFormData(payload, ['date']));
      }}
      scrollToFirstError
    >
      {product ? (
        <Form.Item name="product" label="Product">
          <Card
            style={{ width: 200 }}
            cover={<img alt={product.name} src={product.image} />}
          >
            <Card.Meta title={product.name} description={product.description} />
          </Card>
        </Form.Item>
      ) : null}

      <Form.Item name="product_variant" label="Variant">
        <DebounceSelect
          apiCall={variantApiCall.list}
          params={
            initialValues.product_variant
              ? {
                  product: product_id,
                  product_variant: initialValues.product_variant.id,
                }
              : { product: product_id }
          }
          placeholder="Select product variant"
          displayFn={map(o => ({
            value: o.id,
            label: o.name,
          }))}
        />
      </Form.Item>

      <Form.Item name="inventory_type" label="Type" initialValue={'purchase'}>
        <Select>
          {options.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="date" label="Date">
        <DatePicker />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity">
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>
      <Form.Item name="price" label="Price">
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>
      <Form.Item name="vendor_name" label="Supplier/Customer">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Confirm
          </Button>
          <Button type="link" href={`/app/inventory-tracking`}>
            Cancel
          </Button>
          {/* {id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id, product_id });
                }
              }}
            >
              Delete
            </Button>
          )} */}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
