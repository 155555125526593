import { Button, Form, Input, Space, Tooltip } from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { omitBy } from 'lodash/fp';
import { useSearchParams } from 'react-router-dom';
import React from 'react';

import { convertDateFormData, userSelectDisplayFn } from 'utils/formData';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import branchApiCall from 'app/apiCalls/branch';
import userApiCall from 'app/apiCalls/account/all';

const SearchAndFilterForm = ({ name, searchOption, filterOptions }) => {
  const [form] = Form.useForm();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleReset = () => {
    form.resetFields();
    form.submit();
  };

  const handleSubmit = values => {
    const payload = convertDateFormData(values, ['date__gte', 'date__lte']);
    const existingFilter = Object.fromEntries(searchParams);
    const filterParams = { ...existingFilter, ...payload };
    const searchFilterParams = omitBy(value => !value)(filterParams);
    setSearchParams(searchFilterParams);
  };

  const searchEnabled = !!searchOption;
  const { placeholder, tooltip } = searchOption || {};

  const hasFilters = !!filterOptions;
  const { filters } = filterOptions || {};
  const hasDateFilter = filters?.includes('date');
  const hasBranchFilter = filters?.includes('branches');
  const hasCustomerFilter = filters?.includes('users');

  const getSearchLabel = () => {
    if (!tooltip) {
      return 'Search';
    }

    return (
      <Space size={3}>
        Search
        <Tooltip title={tooltip}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    );
  };

  return (
    <Form
      form={form}
      layout="inline"
      name={name || 'search_filter_form'}
      onFinish={handleSubmit}
      initialValues={{ search: searchParams.get('search') }}
    >
      {hasDateFilter && (
        <>
          <Form.Item name="date__gte" label="From">
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
          <Form.Item name="date__lte" label="To">
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
        </>
      )}

      {searchEnabled && (
        <Form.Item name="search" label={getSearchLabel()}>
          <Space>
            <Input placeholder={placeholder || 'Enter to search'} />
          </Space>
        </Form.Item>
      )}

      {hasCustomerFilter && (
        <Form.Item name="user_id" label="Customer">
          <DebounceSelect
            placeholder="All customers"
            apiCall={userApiCall}
            fieldNames={{ label: 'name', value: 'id' }}
            displayFn={userSelectDisplayFn}
          />
        </Form.Item>
      )}

      {hasBranchFilter && (
        <Form.Item name="branch_id" label="Branch">
          <DebounceSelect
            apiCall={branchApiCall.list}
            placeholder="Select branch"
            fieldNames={{ label: 'name', value: 'id' }}
          />
        </Form.Item>
      )}

      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            iconPosition="end"
          >
            Search
          </Button>
          {hasFilters && (
            <Button type="primary" onClick={() => handleReset()}>
              Reset
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SearchAndFilterForm;
