import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_ORDER_ITEM_CREATE_API_ID = 'DASHBOARD/FETCH_ORDER_ITEM_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_ORDER_ITEM_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/order-items`,
    method: 'POST',
    body: payload,
  })
);

export default apiCall;
