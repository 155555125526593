import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const FETCH_PAYMENT_TRANSACTION_CREATE_API_ID =
  'DASHBOARD/FETCH_PAYMENT_TRANSACTION_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PAYMENT_TRANSACTION_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/payment-transactions`,
    method: 'POST',
    body: payload,
    notify: true,
    isStay: true,
  })
);

export default apiCall;
