import { API_BASE_URL } from 'config/env';
import axios from 'utils/enhancedAxios';

const FETCH_PURCHASE_ORDER_ITEM_EDIT_API_ID =
  'DASHBOARD/FETCH_PURCHASE_ORDER_ITEM_EDIT_API';

const apiCall = async ({ id, payload }) => {
  const axiosOptions = {
    url: `${API_BASE_URL}/admin/v1/purchase-order-items/${id}`,
    method: 'PUT',
    data: payload,
  };
  return await axios(axiosOptions);
};

export default {
  queryKey: FETCH_PURCHASE_ORDER_ITEM_EDIT_API_ID,
  queryFn: apiCall,
};
