import apiCall from 'app/apiCalls/inventoryTracking';
import orderItemApiCall from 'app/apiCalls/orderItem';
import productApiCall from 'app/apiCalls/product';
import variantApiCall from 'app/apiCalls/productVariant';
import purchaseItemApiCall from 'app/apiCalls/purchaseOrderItem';
import { useFetchApiGet } from 'common/reduxutils';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import StockDetail from './StockDetail';
import {
  orderItemColumnsGenerator,
  productColumnsGenerator,
  purchaseOrderItemColumnsGenerator,
  variantColumnsGenerator,
} from './config';

export const ProductPurchaseListPage = props => {
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(productApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchItem({ id: params.product_id });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <StockDetail item={item} />
      <hr className="my-10" />
      <ListPage
        showBreadscrumb={false}
        title="All Purchase Order Items"
        apiCall={purchaseItemApiCall}
        columnGenerator={props => purchaseOrderItemColumnsGenerator(props)}
        resourceName="items"
        apiCallSuffix="purchase"
        {...props}
      />
    </>
  );
};

export const ProductSystemSaleListPage = props => {
  const params = useParams();
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(productApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchItem({ id: params.product_id });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <StockDetail item={item} />
      <hr className="my-10" />
      <ListPage
        showBreadscrumb={false}
        title="All Order Items"
        apiCall={orderItemApiCall}
        columnGenerator={props => orderItemColumnsGenerator(props)}
        resourceName="items"
        apiCallSuffix="sale"
        {...props}
      />
    </>
  );
};

export const ProductStockListPage = (props, { params }) => {
  return (
    <ListPage
      searchOptions={{ tooltip: false }}
      title="Stock Tracking by Product"
      showBreadscrumb={true}
      apiCall={productApiCall}
      params={params}
      columnGenerator={props => productColumnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};

export const VariantStockListPage = (props, { params }) => {
  return (
    <ListPage
      searchOptions={{ tooltip: false }}
      title="Stock Tracking"
      showBreadscrumb={true}
      apiCall={variantApiCall}
      params={params}
      columnGenerator={props => variantColumnsGenerator(props)}
      resourceName="items"
      {...props}
    />
  );
};

export const ProductStockDetailPage = props => {
  const params = useParams();
  return (
    <>
      <ListPage
        showBreadscrumb={false}
        title="Purchase"
        apiCall={apiCall}
        columnGenerator={props => stockColumnsGenerator(props)}
        resourceName="items"
        params={{ inventory_type: 'purchase' }}
        createUrl={`/app/stock-tracking/${params.product_id}/create`}
        apiCallSuffix="purchase"
        {...props}
      />
      <ListPage
        showBreadscrumb={false}
        title="Sale"
        apiCall={apiCall}
        columnGenerator={props => stockColumnsGenerator(props)}
        resourceName="items"
        apiCallSuffix="sale"
        params={{ inventory_type: 'sale' }}
        {...props}
      />
    </>
  );
};

export const ProductStockEditPage = props => {
  return (
    <EditPage
      title="Transaction Item"
      apiCall={apiCall}
      listUrl={`/app/stock-tracking`}
      ItemForm={ItemForm}
      resourceName="item"
      {...props}
    />
  );
};

export const ProductStockCreatePage = props => (
  <CreatePage
    title="Create transaction"
    apiCall={apiCall}
    listUrl={`/app/stock-tracking`}
    ItemForm={ItemForm}
    resourceName="item"
    {...props}
  />
);
