import { Col, Row, Statistic, Typography } from 'antd';

const { Title } = Typography;

const StockDetail = ({ item }) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Title level={4}>{item.code}</Title>
        </Col>
        <Col span={8}>
          <Title level={4}>{item.name}</Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Available stock" value={item.available_quantity} />
        </Col>
        <Col span={8}>
          <Statistic
            title="Total Purchases"
            value={item.total_purchase_quantity}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Total sales"
            value={item.total_system_sale_quantity}
          />
        </Col>
      </Row>
    </>
  );
};

export default StockDetail;
