import { Button } from 'antd';

import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import apiCall from 'app/apiCalls/paymentTx';

import { columnsGenerator } from './config';
import PaymentVoucherItemDetail from './paymentVoucher';
import ItemDetail from './detail';
import PaymentTxForm from './forms/PaymentTxForm';

export const PaymentTxListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: false }}
    filterOptions={{ filters: ["date"] }}
    title="All Payment Transactions"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    extra={[
      <Button
        key="inflow"
        type="primary"
        href={'/app/payment/transactions/create/inflow'}
      >
        Money In
      </Button>,
      <Button
        key="outflow"
        danger
        href={'/app/payment/transactions/create/outflow'}
      >
        Payment
      </Button>,
    ]}
    {...props}
  />
);

export const PaymentTxDetailPage = props => (
  <DetailPage
    title="Payment Transaction Detail"
    apiCall={apiCall}
    listUrl={`/app/payment/transactions`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PaymentTxEditPage = props => (
  <EditPage
    title="Payment Transaction"
    apiCall={apiCall}
    listUrl={`/app/payment/transactions`}
    ItemForm={PaymentTxForm}
    resourceName=""
    {...props}
  />
);

export const PaymentTxCreatePage = props => (
  <CreatePage
    title="Payment Transaction"
    apiCall={apiCall}
    listUrl={`/app/payment/transactions`}
    ItemForm={PaymentTxForm}
    resourceName=""
    {...props}
  />
);

export const PaymentTxInvoicePage = props => (
  <DetailPage
    title="Payment Voucher"
    apiCall={apiCall}
    listUrl={`/app/payment/transactions`}
    ItemDetail={PaymentVoucherItemDetail}
    resourceName=""
    {...props}
  />
);
