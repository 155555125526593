import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const CREATE_SUPPLIER_API_ID = 'DASHBOARD/CREATE_SUPPLIER_API_ID';

const apiCall = enhancedMakeFetchAction(
  CREATE_SUPPLIER_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/users`,
    method: 'POST',
    body: { ...payload, account_type: 'supplier' },
    notify: true,
  })
);

export default apiCall;
