import { Button, Card, Descriptions, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-col gap-6 py-4 ">
        <Card
          title={<Title level={3}>Account Detail</Title>}
          extra={
            <Button type="primary">
              <Link to={`/app/users/${item.id}/edit`}>Edit</Link>
            </Button>
          }
        >
          <Descriptions column={1} bordered>
            <DescItem label="Company Name">{item.name}</DescItem>
            <DescItem label="Person in charge">
              {item.person_in_charge}
            </DescItem>
            <DescItem label="Phone">{item.phone}</DescItem>
            <DescItem label="Email">{item.email}</DescItem>
            <DescItem label="Address 1">{item.address1}</DescItem>
            <DescItem label="Address 2">{item.address2}</DescItem>
            <DescItem label="Address 3">{item.address3}</DescItem>
            <DescItem label="TIN">{item.tax_number}</DescItem>
            <DescItem label="Admin notes">{item.admin_notes}</DescItem>
            <DescItem label="Joined At">
              {formatDateTime(item.date_joined)}
            </DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
