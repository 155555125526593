import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const FETCH_PAYMENT_TX_ITEM_EDIT_API_ID =
  'DASHBOARD/FETCH_PAYMENT_TX_ITEM_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PAYMENT_TX_ITEM_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/payment-tx-items/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
    isStay: true,
  })
);

export default apiCall;
