import apiCall from 'app/apiCalls/category';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const CategoryListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: "Enter product name or odering" }}
    title="All Categories"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/categories/create`}
    {...props}
  />
);

export const CategoryEditPage = props => (
  <EditPage
    title="Category"
    apiCall={apiCall}
    listUrl={`/app/categories`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const CategoryCreatePage = props => (
  <CreatePage
    title="Categories"
    apiCall={apiCall}
    listUrl={`/app/categories`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
