import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Upload,
  notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/skeleton/Title';

import {
  convertDateFormData,
  ensureDateFields,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { formatCurrency } from 'utils/formatAmount';
import { paymentMethods } from 'app/constants/paymentMethod';
import {
  statusOptions as options,
  statusColors as paymentStatusColors,
  statusLabels as paymentStatusLabels,
} from 'app/constants/status';
import { useFormBlocker } from 'hooks';
import DataTable from 'common/ui/DataTable';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import apiCall from 'app/apiCalls/paymentTx';
import paymentAccountApiCall from 'app/apiCalls/paymentAccount';
import userApiCall from 'app/apiCalls/account/all';

import PaymentTxItemForm from './PaymentTxItemForm';

const dateFields = ['date'];

const usePaymentTxQuery = id => {
  return useQuery({
    queryKey: [apiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return apiCall.detail.queryFn({ id });
      }

      return Promise.resolve('');
    },
  });
};

const ensureFormData = values => {
  let payload = ensureDateFields(values, dateFields);
  if (payload.payment_account && payload.payment_account.id) {
    payload = {
      ...payload,
      payment_account: payload.payment_account.id,
    };
  }
  if (payload.user && payload.user.id) {
    payload = {
      ...payload,
      user: payload.user.id,
    };
  }
  return payload;
};

const PaymentTxForm = ({
  form,
  initialValues = { status: 1001 },
  name,
  formMode = 'create',
}) => {
  const setShouldBlock = useFormBlocker(form);
  const urlParams = useParams();
  const { id, type } = urlParams;
  const navigate = useNavigate();
  const formData = Form.useWatch([], form) || {};

  const transactionId = id || formData.id;

  // hooks definitions
  const { data: transaction = {} } = usePaymentTxQuery(transactionId);

  const { mutate: updateTx, isLoading: updating } = useMutation({
    mutationFn: apiCall.edit.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
      navigate(-1);
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const [shouldGoBack, setShouldGoBack] = useState(false);

  const { mutate: createTx, isLoading: creating } = useMutation({
    mutationFn: apiCall.create.queryFn,
    onSuccess: createdItem => {
      form.setFieldsValue({
        id: createdItem.id,
        code: createdItem.code,
        status: createdItem.status,
      });

      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
      navigate(-1);
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Something went wrong!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  const dataItem = {
    ...initialValues,
    ...(type ? { payment_type: type } : {}),
    ...transaction,
  };

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.payment_account &&
      !!formData.user &&
      !!formData.date;

    if (isAutoSave) {
      setShouldBlock(false);
      console.log('auto save..');
      form.submit();
    }
  }, [formData]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={ensureFormData(dataItem)}
      name={name || 'payment_tx_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        if (values.id) {
          return submitData(
            updateTx,
            convertDateFormData(values, dateFields),
            urlParams,
            ['file']
          );
        }

        return submitData(
          createTx,
          convertDateFormData(values, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="payment_type">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="Type">
        {dataItem.payment_type === 'inflow' ? 'Money In' : 'Make Payment'}
      </Form.Item>
      <Form.Item label="PV No.">
        {dataItem.code || '[auto generated]'}
      </Form.Item>
      {formMode == 'edit' && dataItem.id ? (
        <Form.Item name="status" label="Status">
          <Select>
            {options.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item label="Status">
          <Tag color={paymentStatusColors[transaction.status || 1000]}>
            {paymentStatusLabels[transaction.status || 1000]}
          </Tag>
        </Form.Item>
      )}

      <Form.Item
        name="payment_account"
        label={dataItem.payment_type == 'inflow' ? 'Pay To' : 'Pay From'}
        required
      >
        <DebounceSelect
          apiCall={paymentAccountApiCall.list}
          params={
            formData.payment_account
              ? { id: formData.payment_account.id }
              : {
                  account_type__in: 'customer,supplier,payment',
                }
          }
          placeholder="Select which cash or bank account"
          fieldNames={{ value: 'id', label: 'name' }}
        />
      </Form.Item>

      <Form.Item
        name="user"
        label={dataItem.payment_type == 'inflow' ? 'Pay From' : 'Pay To'}
        required
      >
        <DebounceSelect
          apiCall={userApiCall}
          params={
            formData.user
              ? { id: formData.user.id }
              : { account_type__in: 'customer,supplier,payment' }
          }
          fieldNames={{ value: 'id', label: 'name' }}
          displayFn={userSelectDisplayFn}
          placeholder="Select debtor or creditor"
        />
      </Form.Item>

      <Form.Item name="reference_number" label="Reference Number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="date" label="Date" required>
        <DatePicker />
      </Form.Item>

      <Card className="my-5" title={<Title level={3}>Items</Title>}>
        <XModal title={'New item'} isReady={!!formData.id}>
          <PaymentTxItemForm params={{ transactionId }} />
        </XModal>

        <DataTable
          rowKey="id"
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Payment Mode',
              dataIndex: 'payment_method',
              render: value => paymentMethods[value],
              align: 'center',
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Action',
              key: 'action',
              render: (text, record) => (
                <XModal title={'Edit item'}>
                  <PaymentTxItemForm
                    params={{ id: record.id, transactionId: transactionId }}
                  />
                </XModal>
              ),
            },
          ]}
          dataSource={dataItem.tx_items || []}
          totalItems={dataItem.tx_items?.length || 0}
          currentPage={1}
          defaultCurrent={1}
        />
      </Card>
      <Card>
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3"></div>
          <div className="">
            <p>Sub Total:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="">
            <p>{formatCurrency(dataItem.sub_total)}</p>
            <p>{formatCurrency(dataItem.discount)}</p>
            <p>{formatCurrency(dataItem.rounding_adjustment)}</p>
            <p>{formatCurrency(dataItem.amount)}</p>
          </div>
        </div>
      </Card>
      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="personal_notes" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={creating || updating}
            onClick={() => setShouldGoBack(true)}
          >
            Save
          </Button>
          {formData.id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id: formData.id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default PaymentTxForm;
