import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const EDIT_MANAGER_API_ID = 'DASHBOARD/EDIT_MANAGER_API_ID';

const apiCall = enhancedMakeFetchAction(
  EDIT_MANAGER_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/staffs/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
