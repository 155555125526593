import { Layout, Menu } from 'antd';
import useAdminAuth from 'hooks/useAdminAuth';
import { items, managerList, openKeys } from './config';

const SideMenu = () => {
  const { user } = useAdminAuth();
  const menuItems = user && user.account_type === 'admin' ? items : managerList;
  return (
    <Layout.Sider theme="dark" breakpoint="xl" collapsedWidth="0">
      <Menu
        theme="dark"
        mode="inline"
        items={menuItems}
        defaultOpenKeys={openKeys}
      />
    </Layout.Sider>
  );
};

export default SideMenu;
