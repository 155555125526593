import { useFetchApiGet } from 'common/reduxutils';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AdminDetail = ({
  listUrl,
  apiCall,
  options = { title: 'View detail', deleteEnabled: false },
  ItemDetail,
  resourceName,
}) => {
  const params = useParams();
  const { title, deleteEnabled } = options;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName });

  useEffect(() => {
    fetchItem({ id: params.id });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={title} backUrl={listUrl} />
      <ItemDetail item={item} apiCall={apiCall} />
    </>
  );
};

export default AdminDetail;
