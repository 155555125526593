import { Button, Tag } from 'antd';
import { orderStatusColors, orderStatusLabels } from 'app/constants/order';
import { capitalize } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency, formatNumber } from 'utils/formatAmount';

function getItemUrl(inventoryType, id) {
  if (inventoryType === 'purchase') return `/app/purchase-orders/${id}`;
  else if (inventoryType === 'sales') return `/app/orders/${id}`;
  return '';
}

export const productColumnsGenerator = params => [
  {
    title: 'Product ID',
    dataIndex: 'code',
    render: (value, record) => (
      <Link to={`/app/products/${record.id}`}>{value}</Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Total Purchase',
    dataIndex: 'total_purchase_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Total Sale',
    dataIndex: 'total_sale_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Stock Available',
    align: 'right',
    dataIndex: 'available_quantity',
    render: value => formatNumber(value),
  },
  {
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/app/stock-tracking/${record.id}/purchases`}>
            Purchases
          </Link>
        </Button>
        <Button shape="round">
          <Link to={`/app/stock-tracking/${record.id}/order-items`}>Sales</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const variantColumnsGenerator = params => [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => product.name,
  },
  {
    title: 'Product ID',
    dataIndex: 'product',
    render: product => product.code,
  },
  {
    title: 'Variant ID',
    dataIndex: 'code',
  },
  {
    title: 'Unit',
    dataIndex: 'product',
    render: product => product.unit,
  },
  {
    title: 'Price (RM)',
    dataIndex: 'price',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'T Purchase',
    dataIndex: 'total_purchase_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'T Sale',
    dataIndex: 'total_sale_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Stock Balance',
    align: 'right',
    dataIndex: 'available_quantity',
    render: value => formatNumber(value),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/app/stock-tracking/${record.id}`}>View</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const purchaseOrderItemColumnsGenerator = params => [
  {
    title: 'Variant',
    dataIndex: 'product_variant',
    render: value => value?.name,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Supplier',
    dataIndex: 'purchase_order',
    render: value => value?.user?.name,
  },
  {
    title: 'Invoice',
    dataIndex: 'purchase_order',
    render: value =>
      value ? (
        <a target="_blank" href={value.invoice}>
          View Invoice
        </a>
      ) : null,
  },
  {
    title: 'Status',
    dataIndex: 'purchase_order',
    render: order => (
      <Tag color={orderStatusColors[order.status]}>
        {orderStatusLabels[order.status]}
      </Tag>
    ),
  },
  {
    title: 'Purchase',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/app/purchase-orders/${record.purchase_order.id}`}>
            View detail
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const orderItemColumnsGenerator = params => [
  {
    title: 'Variant',
    dataIndex: 'product_variant',
    render: value => value?.name,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Created At',
    dataIndex: 'created',
    align: 'right',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/app/orders/${record.order}`}>View order detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const statementColumns = props => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'Particulars',
    dataIndex: 'item_code',
    align: 'left',
    render: (value, record) => (
      <>
        {capitalize(record.inventory_type)}{' '}
        <Link to={getItemUrl(record.inventory_type, record.item_id)}>
          {value}
        </Link>
      </>
    ),
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    align: 'center',
  },
  {
    title: 'Quantity In',
    dataIndex: 'quantity_in',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
  {
    title: 'Quantity Out',
    dataIndex: 'quantity_out',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => (value ? formatCurrency(value) : ''),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
];
