import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const CREATE_PURCHASE_ORDER_API_ID =
  'DASHBOARD/CREATE_PURCHASE_ORDER_API_ID';

const apiCall = enhancedMakeFetchAction(
  CREATE_PURCHASE_ORDER_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/purchase-orders`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
