import apiCall from 'app/apiCalls/paymentAccount';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const PaymentBookListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: false }}
    filterOptions={{ filters: ["date"] }}
    title="Cash & Bank"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/payment/accounts/create`}
    {...props}
  />
);

export const PaymentBookDetailPage = props => (
  <DetailPage
    title="Cash & Bank"
    apiCall={apiCall}
    listUrl={`/app/payment/accounts`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PaymentBookEditPage = props => (
  <EditPage
    title="Cash & Bank"
    apiCall={apiCall}
    listUrl={`/app/payment/accounts`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const PaymentBookCreatePage = props => (
  <CreatePage
    title="Cash & Bank"
    apiCall={apiCall}
    listUrl={`/app/payment/accounts`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
