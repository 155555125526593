export const companyInfo = {
  name: "SING SENG ENTERPRISE SDN BHD (1160536-H)",
  address1: "P27, BAGAN PANCHOR, ",
  address2: "34900, PANTAI REMIS, ",
  address3: "PERAK, MALAYSIA.",
  phone: "+60125212132",
  email: "singseng@gmail.com",
  tax_number: "E000123019889"
}

export const accountTypes = {
  PAYMENT: "payment",
}
