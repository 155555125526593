import { Space } from 'antd';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/dateUtils';

export const columnsGenerator = props => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    align: 'right',
  },
  {
    title: 'Date Joined',
    dataIndex: 'date_joined',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={`/app/managers/${record.id}`}>View</Link>
        <Link to={`/app/managers/${record.id}/edit`}>Edit</Link>
      </Space>
    ),
  },
];
