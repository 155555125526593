import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_VARIANT_CREATE_API_ID = 'DASHBOARD/FETCH_VARIANT_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_VARIANT_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/variants`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
