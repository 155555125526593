import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const FETCH_ORDER_EDIT_API_ID = 'DASHBOARD/FETCH_ORDER_EDIT_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_ORDER_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/orders/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
