import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_EXPENSE_TYPE_STATEMENT_API =
  'EXPENSE/FETCH_EXPENSE_TYPE_STATEMENT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_EXPENSE_TYPE_STATEMENT_API,
  ({ id, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/admin/v1/expense-types/${id}/statement`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
