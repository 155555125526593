import { Button, Form, Input, InputNumber, Select, Space } from 'antd';
import apiCall from 'app/apiCalls/branch';
import { usePostApi } from 'common/reduxutils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const paymentTypes = [
  { value: 'cash', label: 'Cash' },
  { value: 'bank', label: 'Bank' },
];

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const { id } = useParams();

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'branch_form'}
      onFinish={values => onFinish(values)}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name (English)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_ms" label="Name (Malay)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_zh" label="Name (Chinese)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="ordering" label="Ordering">
        <InputNumber placeholder="" />
      </Form.Item>

      <Form.Item name="payment_type" label="Cash or Bank">
        <Select>
          {paymentTypes.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="bank_name" label="Bank Name">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="bank_account_number" label="Bank Account Number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Save
          </Button>
          {id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
