import { Tag } from 'antd';
import {
  journalStatusColors,
  journalStatusLabels,
} from 'app/constants/journal';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getItemLinkPrefix } from 'utils/journal';

export const columnsGenerator = params => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'Invoice Number',
    dataIndex: 'item_code',
    align: 'left',
    render: (item_code, record) => (
      <>
        <Link to={`${getItemLinkPrefix(record.item_type)}/${record.item_id}`}>
          {item_code}
        </Link>
      </>
    ),
  },
  {
    title: 'Particular',
    dataIndex: 'title',
    align: 'left',
    render: (value, record) => {
      if (value) return capitalize(value);
      if (record.journal_type === 'payment') return record.user?.name;
      return capitalize(record.journal_type);
    },
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },

  {
    title: 'Modified Date',
    dataIndex: 'modified',
    align: 'left',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={journalStatusColors[status]}>
          {journalStatusLabels[status]}
        </Tag>
      );
    },
  },
];
