import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_USER_DETAIL_API_ID = 'ACCOUNT/FETCH_USER_DETAIL_API_ID';

const apiCall = enhancedMakeFetchAction(FETCH_USER_DETAIL_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/admin/v1/users/${id}`,
  method: 'GET',
}));

export default apiCall;
