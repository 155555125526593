import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const CREATE_ADMIN_API_ID = 'DASHBOARD/CREATE_ADMIN_API_ID';

const apiCall = enhancedMakeFetchAction(CREATE_ADMIN_API_ID, ({ payload }) => ({
  endpoint: `${API_BASE_URL}/admin/v1/staffs`,
  method: 'POST',
  body: { ...payload, account_type: 'admin' },
  notify: true,
}));

export default apiCall;
