import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const DELETE_EXPENSE_API_ID = 'DASHBOARD/DELETE_EXPENSE_API_ID';

const apiCall = enhancedMakeFetchAction(DELETE_EXPENSE_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/admin/v1/expenses/${id}`,
  method: 'DELETE',
  notify: true,
}));

export default apiCall;
