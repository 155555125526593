import axios from 'utils/enhancedAxios';
import qs from 'qs';

// This function helps remove undefined and null params
// Ex generateEndpoint({host: "abc.com", params: {a: 1, b: undefined, c: null}})
// => "abc.com?a=1&c="
const generateEndpoint = ({ host, params }) => {
  const queryString = qs.stringify(params, { arrayFormat: 'brackets' });

  return queryString ? `${host}?${queryString}` : host;
};

const enhancedMakeFetchAction = (apiName, endpointGenerator) => {
  const apiCall = async params => {
    const options = endpointGenerator(params);
    const axiosOptions = {
      url: options.endpoint,
      method: options.method,
      data: options.body,
    };

    return await axios(axiosOptions);
  };

  return {
    queryKey: apiName,
    queryFn: apiCall,
  };
};

export { generateEndpoint, enhancedMakeFetchAction };
