import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const DELETE_PAYMENT_TX_ITEM_API_ID =
  'DASHBOARD/DELETE_PAYMENT_TX_ITEM_API_ID';

const apiCall = enhancedMakeFetchAction(
  DELETE_PAYMENT_TX_ITEM_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/payment-tx-items/${id}`,
    method: 'DELETE',
    notify: true,
    isStay: true,
  })
);

export default apiCall;
