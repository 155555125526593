import React from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'common/ui/DataTable';
import XModal from 'components/XModal';
import { formatCurrency } from 'utils/formatAmount';
import {
  statusColors,
  statusLabels,
} from 'app/constants/status';

const sharedTableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => (
      <Link to={`/app/products/${product.id}`}>{product.name}</Link>
    ),
  },
  {
    title: 'Variant',
    dataIndex: 'product_variant',
    render: product_variant =>
      product_variant ? product_variant.name : null,
  },
  {
    title: 'Price per item',
    dataIndex: 'price',
    align: 'right',
    render: (text, record)=> {
      const value = formatCurrency(text);
      const productPrice = record.product_variant?.price || record.product?.price || 0;
      const isPriceAdjusted = record.price !== productPrice;

      return isPriceAdjusted ? <span className="text-amber-700">{value}</span> : value;
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  {
    title: 'Amount',
    dataIndex: 'sub_total',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Discount',
    dataIndex: 'discount_percentage',
    align: 'right',
    render: value => value > 0 ? `${value}%` : '-',
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    align: 'tax',
    render: value => value > 0 ? formatCurrency(value) : '-',
  },
];

const actionColumnData = {
  title: 'Action',
  key: 'action',
};

const OrderItemDataTable = ({
  dataSource = [],
  renderEditOrderItemForm,
  order,
}) => {

  const getActionColumn = () => {
    if (!renderEditOrderItemForm) {
      return [];
    }

    return [{
      ...actionColumnData,
      render: (text, record) => renderEditOrderItemForm && (
        <XModal
          title={'Edit item'}
          onOk={() => {
            reload();
          }}
        >
          {renderEditOrderItemForm(record.id)}
        </XModal>
      ),
    }];
  };

  const columns = [
    ...sharedTableColumns,
    ...getActionColumn(),
  ]

  return (
    <>
      <DataTable
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        totalItems={dataSource.length}
        currentPage={1}
        defaultCurrent={1}
      />

      <div className="grid grid-cols-5 gap-4 mb-5">
        <div className="col-span-3"></div>
        <div className="">
          <p>Sub Total:</p>
          <p>Tax:</p>
          <p>Discount Given:</p>
          <p>Rounding Adjustment:</p>
          <p>Total Amount:</p>
        </div>
        <div className="">
          <p>{formatCurrency(order.sub_total)}</p>
          <p>{formatCurrency(order.tax)}</p>
          <p>{formatCurrency(order.discount)}</p>
          <p>{formatCurrency(order.rounding_adjustment)}</p>
          <p>{formatCurrency(order.total_amount)}</p>
        </div>
      </div>
    </>
  );
};

export default OrderItemDataTable;
