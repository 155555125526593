import apiCall from 'app/apiCalls/expense';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import ItemForm from './forms/ExpenseForm';
import InvoiceItemDetail from './invoice';

export const ExpenseListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: "Enter invoice number, username or email" }}
    filterOptions={{ filters: ["date", "branches"] }}
    title="All Expenses"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/expenses/create`}
    {...props}
  />
);

export const ExpenseDetailPage = props => (
  <DetailPage
    title="Expense Detail"
    apiCall={apiCall}
    listUrl={`/app/expenses`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const ExpenseEditPage = props => (
  <EditPage
    title="Expense"
    apiCall={apiCall}
    listUrl={`/app/expenses`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const ExpenseCreatePage = props => (
  <CreatePage
    title="Expenses"
    apiCall={apiCall}
    listUrl={`/app/expenses`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const ExpenseInvoicePage = props => (
  <DetailPage
    title="Invoice"
    apiCall={apiCall}
    listUrl={`/app/expenses`}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);
