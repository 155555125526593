import { Card, Descriptions } from 'antd';
import inventoryEntryApiCall from 'app/apiCalls/inventoryEntry';
import productVariantApiCall from 'app/apiCalls/productVariant';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { StatementPage } from 'components/statement/StatementPage';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency, formatNumber } from 'utils/formatAmount';
import { statementColumns } from './config';

export const InventoryStatementPage = props => {
  const params = { status__in: '1001,1002' };

  return (
    <StatementPage
      ItemCard={ItemCard}
      params={params}
      statementApiCall={inventoryEntryApiCall}
      title="Balance Statement"
      statementColumns={statementColumns}
      {...props}
    />
  );
};

const ItemCard = props => {
  const { balance, avg_purchase_price, inventory_value } = props;
  const { product_variant_id: id } = useParams();
  const {
    data: item = {},
    load: fetchProductVariant,
    isLoading: isLoading,
  } = useFetchApiGet(productVariantApiCall.detail);

  useEffect(() => {
    if (id) {
      fetchProductVariant({ id });
    }
  }, [id]);

  if (isLoading) return <LoadingSpinner />;
  return (
    <Card>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Product Name">
          {item.product?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Product ID">
          {item.product?.code}
        </Descriptions.Item>
        <Descriptions.Item label="Variant ID">{item.code}</Descriptions.Item>
        <Descriptions.Item label="Variant Name">{item.name}</Descriptions.Item>
        <Descriptions.Item label="Average Purchase Price">
          {formatCurrency(avg_purchase_price)}
        </Descriptions.Item>
        <Descriptions.Item label="Stock Balance">
          {formatNumber(balance)}
        </Descriptions.Item>
        <Descriptions.Item label="Value of the stock">
          {formatCurrency(inventory_value)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
