import { SITE_NAME } from 'config/env';
import useAdminAuth from 'hooks/useAdminAuth';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

const Header = () => {
  const { user, isAuthenticated, loading, signOut } = useAdminAuth();
  return (
    <header className="text-gray-600 body-font">
      <div className="container flex flex-wrap md:justify-between flex-col md:flex-row p-5 items-center">
        <Link
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          to={`/`}
        >
          <span className="ml-3 text-xl">{SITE_NAME}</span>
        </Link>
        <div className="md:ml-auto flex flex-wrap items-center text-base justify-center"></div>
        <div className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
          {loading ? (
            '..'
          ) : isAuthenticated ? (
            <UserMenu user={user} signOut={signOut} />
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
