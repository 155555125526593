import { createBrowserRouter, Navigate } from 'react-router-dom';

import {
  AdminCreatePage,
  AdminDetailPage,
  AdminEditPage,
  AdminListPage,
} from 'app/pages/Admin';
import AdminLoginPage from 'app/pages/AdminLogin';
import {
  BranchCreatePage,
  BranchEditPage,
  BranchListPage,
} from 'app/pages/Branch';
import {
  CategoryCreatePage,
  CategoryEditPage,
  CategoryListPage,
} from 'app/pages/Category';
import {
  ExpenseCreatePage,
  ExpenseDetailPage,
  ExpenseEditPage,
  ExpenseInvoicePage,
  ExpenseListPage,
} from 'app/pages/Expense';
import {
  ExpenseTypeCreatePage,
  ExpenseTypeDetailPage,
  ExpenseTypeEditPage,
  ExpenseTypeListPage,
} from 'app/pages/ExpenseType';
import { ExpenseStatementPage } from 'app/pages/ExpenseType/ExpenseStatementPage';
import Homepage from 'app/pages/Homepage';
import { JournalEntryListPage } from 'app/pages/JournalEntry';
import {
  ManagerCreatePage,
  ManagerDetailPage,
  ManagerEditPage,
  ManagerListPage,
} from 'app/pages/Manager';
import {
  OrderCreatePage,
  OrderDetailPage,
  OrderEditPage,
  OrderInvoicePage,
  OrderListPage,
} from 'app/pages/Order';
import PageNotFound from 'app/pages/PageNotFound';
import {
  PaymentBookCreatePage,
  PaymentBookDetailPage,
  PaymentBookEditPage,
  PaymentBookListPage,
} from 'app/pages/PaymentAccount';
import { PaymentStatementPage } from 'app/pages/PaymentAccount/PaymentStatementPage';
import {
  PaymentTxCreatePage,
  PaymentTxDetailPage,
  PaymentTxEditPage,
  PaymentTxInvoicePage,
  PaymentTxListPage,
} from 'app/pages/PaymentTx';
import {
  ProductCreatePage,
  ProductDetailPage,
  ProductEditPage,
  ProductListPage,
} from 'app/pages/Product';
import {
  ProductPurchaseListPage,
  ProductStockCreatePage,
  ProductStockDetailPage,
  ProductStockEditPage,
  ProductStockListPage,
  ProductSystemSaleListPage,
  VariantStockListPage,
} from 'app/pages/ProductStock';
import { InventoryStatementPage } from 'app/pages/ProductStock/InventoryStatementPage';
import {
  PurchaseOrderCreatePage,
  PurchaseOrderDetailPage,
  PurchaseOrderEditPage,
  PurchaseOrderInvoicePage,
  PurchaseOrderListPage,
} from 'app/pages/Purchase';
import {
  SupplierCreatePage,
  SupplierDetailPage,
  SupplierEditPage,
  SupplierListPage,
} from 'app/pages/Supplier';
import { SupplierStatementPage } from 'app/pages/Supplier/SupplierStatementPage';
import {
  UserCreatePage,
  UserDetailPage,
  UserEditPage,
  UserListPage,
} from 'app/pages/User';
import { CustomerStatementPage } from 'app/pages/User/CustomerStatementPage';
import { VariantCreatePage, VariantEditPage } from 'app/pages/Variant';
import PermissionError from 'common/ui/PermissionError';
import AppLayout from 'components/Layout';
import GuestLayout from 'components/LayoutGuest';
import { RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
  {
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <AdminLoginPage />,
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: '/app',
        element: <Homepage />,
      },
      {
        path: '/app/products',
        element: <ProductListPage />,
      },
      {
        path: '/app/products/create',
        element: <ProductCreatePage />,
      },
      {
        path: '/app/products/:id',
        element: <ProductDetailPage />,
      },
      {
        path: '/app/products/:product_id/variants/create',
        element: <VariantCreatePage />,
      },
      {
        path: '/app/products/:product_id/variants/:id/edit',
        element: <VariantEditPage />,
      },
      {
        path: '/app/products/:id/edit',
        element: <ProductEditPage />,
      },
      {
        path: '/app/branches',
        element: <BranchListPage />,
      },
      {
        path: '/app/branches/create',
        element: <BranchCreatePage />,
      },
      {
        path: '/app/branches/:id/edit',
        element: <BranchEditPage />,
      },
      {
        path: '/app/payment/accounts',
        element: <PaymentBookListPage />,
      },
      {
        path: '/app/payment/accounts/:id/statement/:user_id',
        element: <PaymentStatementPage />,
      },
      {
        path: '/app/payment/accounts/:id',
        element: <PaymentBookDetailPage />,
      },
      {
        path: '/app/payment/accounts/:id/edit',
        element: <PaymentBookEditPage />,
      },
      {
        path: '/app/payment/accounts/create',
        element: <PaymentBookCreatePage />,
      },
      {
        path: '/app/payment/transactions',
        element: <PaymentTxListPage />,
      },
      {
        path: '/app/payment/transactions/:id/edit',
        element: <PaymentTxEditPage />,
      },
      {
        path: '/app/payment/transactions/:id/invoice',
        element: <PaymentTxInvoicePage />,
      },
      {
        path: '/app/payment/transactions/:id',
        element: <PaymentTxDetailPage />,
      },
      {
        path: '/app/payment/transactions/create/:type',
        element: <PaymentTxCreatePage />,
      },
      {
        path: '/app/stock-tracking',
        element: <VariantStockListPage />,
      },
      {
        path: '/app/stock-tracking/:product_variant_id',
        element: <InventoryStatementPage />,
      },
      {
        path: '/app/stock-tracking/products',
        element: <ProductStockListPage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id',
        element: <ProductStockDetailPage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id/purchases',
        element: <ProductPurchaseListPage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id/order-items',
        element: <ProductSystemSaleListPage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id/stocks/:id/edit',
        element: <ProductStockEditPage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id/create',
        element: <ProductStockCreatePage />,
      },
      {
        path: '/app/stock-tracking/products/:product_id/:inventory_type/create',
        element: <ProductStockCreatePage />,
      },
      {
        path: '/app/orders',
        element: <OrderListPage />,
      },
      {
        path: '/app/orders/create',
        element: <OrderCreatePage />,
      },
      {
        path: '/app/orders/:id',
        element: <OrderDetailPage />,
      },
      {
        path: '/app/orders/:id/edit',
        element: <OrderEditPage />,
      },
      {
        path: '/app/orders/:id/invoice',
        element: <OrderInvoicePage />,
      },
      {
        path: '/app/purchase-orders',
        element: <PurchaseOrderListPage />,
      },
      {
        path: '/app/purchase-orders/create',
        element: <PurchaseOrderCreatePage />,
      },

      {
        path: '/app/purchase-orders/:id',
        element: <PurchaseOrderDetailPage />,
      },
      {
        path: '/app/purchase-orders/:id/edit',
        element: <PurchaseOrderEditPage />,
      },
      {
        path: '/app/purchase-orders/:id/invoice',
        element: <PurchaseOrderInvoicePage />,
      },
      { path: '/app/categories', element: <CategoryListPage /> },
      { path: '/app/categories/create', element: <CategoryCreatePage /> },
      { path: '/app/categories/:id/edit', element: <CategoryEditPage /> },

      { path: '/app/orders', element: <OrderListPage /> },
      { path: '/app/orders/:id', element: <OrderDetailPage /> },
      { path: '/app/orders/:id/edit', element: <OrderEditPage /> },

      { path: '/app/users', element: <UserListPage /> },
      { path: '/app/users/create', element: <UserCreatePage /> },
      { path: '/app/users/:id', element: <UserDetailPage /> },
      { path: '/app/users/:id/edit', element: <UserEditPage /> },
      {
        path: '/app/users/:user_id/statement',
        element: <CustomerStatementPage />,
      },

      { path: '/app/suppliers', element: <SupplierListPage /> },
      { path: '/app/suppliers/create', element: <SupplierCreatePage /> },
      { path: '/app/suppliers/:id', element: <SupplierDetailPage /> },
      { path: '/app/suppliers/:id/edit', element: <SupplierEditPage /> },
      {
        path: '/app/suppliers/:user_id/statement',
        element: <SupplierStatementPage />,
      },

      { path: '/app/administrators', element: <AdminListPage /> },
      { path: '/app/administrators/create', element: <AdminCreatePage /> },
      { path: '/app/administrators/:id', element: <AdminDetailPage /> },
      { path: '/app/administrators/:id/edit', element: <AdminEditPage /> },

      { path: '/app/managers', element: <ManagerListPage /> },
      { path: '/app/managers/create', element: <ManagerCreatePage /> },
      { path: '/app/managers/:id', element: <ManagerDetailPage /> },
      { path: '/app/managers/:id/edit', element: <ManagerEditPage /> },

      { path: '/app/expense-types', element: <ExpenseTypeListPage /> },
      { path: '/app/expense-types/:id', element: <ExpenseTypeDetailPage /> },
      { path: '/app/expense-types/:id/edit', element: <ExpenseTypeEditPage /> },
      {
        path: '/app/expense-types/:id/statement',
        element: <ExpenseStatementPage />,
      },
      { path: '/app/expense-types/create', element: <ExpenseTypeCreatePage /> },

      { path: '/app/expenses', element: <ExpenseListPage /> },
      { path: '/app/expenses/create', element: <ExpenseCreatePage /> },
      { path: '/app/expenses/:id', element: <ExpenseDetailPage /> },
      { path: '/app/expenses/:id/edit', element: <ExpenseEditPage /> },
      { path: '/app/expenses/:id/invoice', element: <ExpenseInvoicePage /> },

      { path: '/app/journal-entries', element: <JournalEntryListPage /> },
      // Add other routes as needed, following the same pattern...
    ],
  },
  {
    path: '/permission',
    element: <PermissionError />,
  },
  {
    path: '/404',
    element: <PageNotFound />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
