import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import {
  expenseStatusColors,
  expenseStatusLabels,
} from 'app/constants/expense';
import DataTable from 'common/ui/DataTable';
import { Link, useParams } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';

const { Title } = Typography;

const ItemDetail = ({ item }) => {
  const { id } = useParams();

  if (!item) {
    return null;
  }

  const supplier = item.user || item.payment_account;

  return (
    <>
      <Card
        title={<Title level={3}>Expense Detail</Title>}
        extra={
          <Space>
            <Button disabled={item.status == 1002 || item.status == 1003}>
              <Link to={`/app/expenses/${item.id}/edit`}>Edit</Link>
            </Button>
            <Button>
              <Link to={`/app/expenses/${item.id}/invoice`}>View invoice</Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Supplier">
            {supplier && (
              <Link to={`/app/suppliers/${supplier.id}`}>
                {supplier.name}
              </Link>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            {item.billing_address}
          </Descriptions.Item>
          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.email}
          </Descriptions.Item>
          <Descriptions.Item label="Expense Type">
            {item.expense_type && (
              <Link to={`/app/expense-types/${item.expense_type.id}`}>
                {item.expense_type.name}
              </Link>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {formatDateTime(item.date)}
          </Descriptions.Item>
          <Descriptions.Item label="Reference Number">
            {item.reference_number}
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Expense No.">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={expenseStatusColors[item.status]}>
              {expenseStatusLabels[item.status]}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card className="my-5" title={<Title level={3}>Expense Items</Title>}>
        <DataTable
          rowKey="id"
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'right',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
          ]}
          dataSource={item.expense_items}
          totalItems={item.expense_items.length}
          currentPage={1}
          defaultCurrent={1}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3"></div>
          <div className="">
            <p>Sub Total:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="">
            <p>{formatCurrency(item.sub_total)}</p>
            <p>{formatCurrency(item.discount)}</p>
            <p>{formatCurrency(item.rounding_adjustment)}</p>
            <p>{formatCurrency(item.amount)}</p>
          </div>
        </div>
      </Card>

      <Card className="my-5">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Personal Notes">
            {item.personal_notes}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
