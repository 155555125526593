import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_BRANCH_EDIT_API_ID = 'DASHBOARD/FETCH_BRANCH_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_BRANCH_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/branches/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
