import apiCall from 'app/apiCalls/expenseType';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import ItemDetail from './detail';
import { columnsGenerator } from './config';

export const ExpenseTypeListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: false }}
    title="All Expense Types"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/expense-types/create`}
    {...props}
  />
);

export const ExpenseTypeDetailPage = props => (
  <DetailPage
    title="Expense Detail"
    apiCall={apiCall}
    listUrl={`/app/expense-types`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const ExpenseTypeEditPage = props => (
  <EditPage
    title="Expense Type"
    apiCall={apiCall}
    listUrl={`/app/expense-types`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const ExpenseTypeCreatePage = props => (
  <CreatePage
    title="Expense Types"
    apiCall={apiCall}
    listUrl={`/app/expense-types`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
