import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_INVENTORY_TRACKING_EDIT_API_ID =
  'DASHBOARD/FETCH_INVENTORY_TRACKING_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVENTORY_TRACKING_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/inventory-trackings/${id}`,
    method: 'PUT',
    body: payload,
    notify: true,
  })
);

export default apiCall;
