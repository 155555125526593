import { Button, Card, Descriptions, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-col gap-6 py-4 ">
        <Card
          title={<Title level={3}>Cash & Bank Detail</Title>}
          extra={
            <Button type="primary">
              <Link to={`/app/payment/accounts/${item.id}/edit`}>Edit</Link>
            </Button>
          }
        >
          <Descriptions column={1} bordered>
            <DescItem label="Bank or Cash">{item.payment_type}</DescItem>
            <DescItem label="Bank or Cash ID">{item.code}</DescItem>
            <DescItem label="Bank or Cash Name (English)">{item.name}</DescItem>
            <DescItem label="Bank or Cash Name (Malay)">
              {item.name_ms}
            </DescItem>
            <DescItem label="Bank or Cash Name (Chinese)">
              {item.name_zh}
            </DescItem>

            <DescItem label="Bank Name">{item.bank_name}</DescItem>
            <DescItem label="Bank Account Number">
              {item.bank_account_number}
            </DescItem>
            <DescItem label="Created At">
              {formatDateTime(item.created)}
            </DescItem>
            <DescItem label="Modified At">
              {formatDateTime(item.modified)}
            </DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
