import {
  AppstoreOutlined,
  CreditCardOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

export const openKeys = [
  'user',
  'order',
  'stock',
  'purchase',
  'payment',
  'expense',
  'journal',
];

function getItem(label, key, url, icon, children, type) {
  return {
    key: url ? url : key,
    icon,
    children,
    label: url ? <NavLink to={url}>{label}</NavLink> : label,
    type,
  };
}

export const items = [
  getItem('Home', 'home', '/app'),
  getItem('Users', 'user', null, <UserOutlined />, [
    getItem('Customers', 'user', '/app/users', null),
    getItem('Suppliers', 'supplier', '/app/suppliers', null),
    getItem('Managers', 'user', '/app/managers', null),
    getItem('Admin', 'user', '/app/administrators', null),
  ]),
  getItem('Catalog', 'catalog', null, <AppstoreOutlined />, [
    getItem('Categories', 'category', '/app/categories', <InboxOutlined />),
    getItem('Products', 'product', '/app/products', <InboxOutlined />),
    getItem('Branches', 'branch', '/app/branches', <InboxOutlined />),
  ]),
  getItem('Stock Tracking', 'stock', '/app/stock-tracking', <InboxOutlined />),
  getItem('Sales', 'order', null, <ShoppingCartOutlined />, [
    getItem(
      'Pending',
      'order',
      '/app/orders?status=1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/orders?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Purchases', 'purchase', null, <ShoppingCartOutlined />, [
    getItem(
      'Pending',
      'order',
      '/app/purchase-orders?status=1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/purchase-orders?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Expense', 'expense', null, <ShoppingCartOutlined />, [
    getItem(
      'Types',
      'expense-type',
      '/app/expense-types',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      '/app/expenses?status__in=1000,1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/expenses?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Cash & Bank Book', 'payment', null, <CreditCardOutlined />, [
    getItem(
      'Accounts',
      'payment-accounts',
      '/app/payment/accounts',
      <CreditCardOutlined />
    ),
    getItem(
      'Pending',
      'payment-transactions',
      '/app/payment/transactions?status__in=1000,1001',
      <CreditCardOutlined />
    ),
    getItem(
      'Completed',
      'payment-transactions',
      '/app/payment/transactions?status=1002',
      <CreditCardOutlined />
    ),
  ]),
  getItem('Journal', 'journal', null, <CreditCardOutlined />, [
    getItem(
      'Pending',
      'journal-entry-pending',
      '/app/journal-entries?status__in=1000,1001',
      null
    ),
    getItem(
      'Completed',
      'journal-entry-completed',
      '/app/journal-entries?status=1002',
      null
    ),
    getItem(
      'Cancelled',
      'journal-entry-cancelled',
      '/app/journal-entries?status__in=1003',
      null
    ),
  ]),
];

export const managerList = [
  getItem('Home', 'home', '/app'),
  getItem('Users', 'user', null, <UserOutlined />, [
    getItem('Customers', 'user', '/app/users', null),
    getItem('Suppliers', 'supplier', '/app/suppliers', null),
  ]),
  getItem('Sales', 'order', null, <ShoppingCartOutlined />, [
    getItem(
      'Pending',
      'order',
      '/app/orders?status=1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/orders?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Purchases', 'purchase', null, <ShoppingCartOutlined />, [
    getItem(
      'Pending',
      'order',
      '/app/purchase-orders?status=1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/purchase-orders?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Expense', 'expense', null, <ShoppingCartOutlined />, [
    getItem(
      'Types',
      'expense-type',
      '/app/expense-types',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      '/app/expenses?status__in=1000,1001',
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      '/app/expenses?status=1002',
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Cash & Bank Book', 'payment', null, <CreditCardOutlined />, [
    getItem(
      'Accounts',
      'payment-accounts',
      '/app/payment/accounts',
      <CreditCardOutlined />
    ),
    getItem(
      'Pending',
      'payment-transactions',
      '/app/payment/transactions?status__in=1000,1001',
      <CreditCardOutlined />
    ),
    getItem(
      'Completed',
      'payment-transactions',
      '/app/payment/transactions?status=1002',
      <CreditCardOutlined />
    ),
  ]),
];
