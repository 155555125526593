import { useParams } from 'react-router-dom';

import apiCall from 'app/apiCalls/customer';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const UserListPage = (props, { params }) => {
  return (
    <ListPage
      searchOptions={{ tooltip: "Enter name, email or phone number" }}
      title="All Customers"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      createUrl={`/app/users/create`}
      {...props}
    />
  );
};

export const UserEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="Users"
      apiCall={apiCall}
      listUrl={`/app/users/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName=""
      {...props}
    />
  );
};

export const UserDetailPage = props => {
  return (
    <DetailPage
      title="Users"
      apiCall={apiCall}
      listUrl={`/app/users`}
      ItemDetail={ItemDetail}
      resourceName=""
      {...props}
    />
  );
};

export const UserCreatePage = props => (
  <CreatePage
    title="Users"
    apiCall={apiCall}
    listUrl={`/app/users`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
