import MetaDecorator from 'components/MetaDecorator';
import SearchAndFilterForm from './SearchAndFilterForm';
import AdminCreate from './create';
import AdminDetail from './detail';
import AdminEdit from './edit';
import AdminList from './list';

export const ListPage = props => {
  const {
    title,
    searchOptions,
    filterOptions,
  } = props;
  const showSearchAndFilter = !!searchOptions || !!filterOptions;

  return (
    <>
      {
        showSearchAndFilter
          ? (
            <SearchAndFilterForm
              searchOption={searchOptions}
              filterOptions={filterOptions}
            />
          ) : null
      }
      <MetaDecorator title={title} />
      <AdminList {...props} />
    </>
  );
};

export const EditPage = props => {
  const { title } = props;

  return (
    <>
      <MetaDecorator title={title} />
      <AdminEdit {...props} />
    </>
  );
};

export const CreatePage = props => {
  const { title } = props;

  return (
    <>
      <MetaDecorator title={title} />
      <AdminCreate {...props} />
    </>
  );
};

export const DetailPage = props => {
  const { title } = props;

  return (
    <>
      <MetaDecorator title={title} />
      <AdminDetail {...props} />
    </>
  );
};
