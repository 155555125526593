import apiCall from 'app/apiCalls/purchase';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import PurchaseForm from './EditForm/PurchaseOrderForm';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import InvoiceItemDetail from './invoice';

export const PurchaseOrderListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: "Enter invoice number, username or email" }}
    filterOptions={{ filters: ["date", "users"] }}
    title="All Purchase Orders"
    apiCall={apiCall}
    createUrl="/app/purchase-orders/create"
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    {...props}
  />
);

export const PurchaseOrderDetailPage = props => (
  <DetailPage
    title="Purchase Order Detail"
    apiCall={apiCall}
    listUrl={`/app/purchase-orders`}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PurchaseOrderEditPage = props => {
  return (
    <EditPage
      title="Edit Purchase Order"
      apiCall={apiCall}
      listUrl={`/app/purchase-orders`}
      ItemForm={PurchaseForm}
      resourceName=""
      {...props}
    />
  );
};

export const PurchaseOrderCreatePage = props => {
  return (
    <CreatePage
      title="Create Purchase Order"
      apiCall={apiCall}
      listUrl={`/app/purchase-orders`}
      ItemForm={PurchaseForm}
      resourceName=""
      {...props}
    />
  );
};

export const PurchaseOrderInvoicePage = props => (
  <DetailPage
    title="Purchase Invoice"
    apiCall={apiCall}
    listUrl={`/app/purchase-orders`}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);
