import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_PAYMENT_TRANSACTION_LIST_API_ID =
  'DASHBOARD/FETCH_PAYMENT_TRANSACTION_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PAYMENT_TRANSACTION_LIST_API_ID,
  params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/admin/v1/payment-transactions`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
