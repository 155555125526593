import apiCall from 'app/apiCalls/productVariant';
import { CreatePage, EditPage } from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';

export const VariantCreatePage = props => {
  const { product_id } = useParams();
  return (
    <CreatePage
      title="Variants"
      apiCall={apiCall}
      listUrl={`/app/products/${product_id}`}
      ItemForm={ItemForm}
      resourceName="item"
      params={{ product: product_id, variant_type: 'size' }}
      {...props}
    />
  );
};

export const VariantEditPage = props => {
  const { product_id, id } = useParams();
  return (
    <EditPage
      title="Variants"
      apiCall={apiCall}
      listUrl={`/app/products/${product_id}`}
      ItemForm={ItemForm}
      resourceName="item"
      params={{ product: product_id, variant_type: 'size' }}
      {...props}
    />
  );
};
