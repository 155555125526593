import { Navigate } from 'react-router-dom';

import LoadingSpinner from 'common/ui/LoadingSpinner';
import useAdminAuth from 'hooks/useAdminAuth';

const PrivateRoute = ({ children }) => {
  const props = useAdminAuth();
  const { loading, isAuthenticated } = props;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    const url = location.pathname
      ? `/login?url=${location.pathname}`
      : '/login';
    return <Navigate to={url} />;
  }

  return children;
};

const GuestRoute = ({ children }) => {
  const props = useAdminAuth();
  console.log(props);
  const { loading, isAuthenticated } = props;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export { GuestRoute, PrivateRoute };
