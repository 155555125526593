import { API_BASE_URL } from 'config/env';
import axios from 'utils/enhancedAxios';

export const CREATE_PURCHASE_ORDER_ITEM_API_ID =
  'DASHBOARD/CREATE_PURCHASE_ORDER_ITEM';

const apiCall = async payload => {
  const axiosOptions = {
    url: `${API_BASE_URL}/admin/v1/purchase-order-items`,
    method: 'POST',
    data: payload,
  };
  return await axios(axiosOptions);
};

export default {
  queryKey: CREATE_PURCHASE_ORDER_ITEM_API_ID,
  queryFn: apiCall,
};
