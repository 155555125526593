import apiCall from 'app/apiCalls/branch';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const BranchListPage = (props, { params }) => (
  <ListPage
    title="All Branches"
    searchOptions={{ tooltip: 'Enter branch name or ordering' }}
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/branches/create`}
    {...props}
  />
);

export const BranchEditPage = props => (
  <EditPage
    title="Branch"
    apiCall={apiCall}
    listUrl={`/app/branches`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const BranchCreatePage = props => (
  <CreatePage
    title="Branches"
    apiCall={apiCall}
    listUrl={`/app/branches`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
