import { useParams } from 'react-router-dom';

import apiCall from 'app/apiCalls/manager';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const ManagerListPage = (props, { params }) => {
  return (
    <ListPage
      searchOptions={{ tooltip: "Enter name, email or phone number" }}
      title="All Managers"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      createUrl={`/app/managers/create`}
      {...props}
    />
  );
};

export const ManagerEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="Managers"
      apiCall={apiCall}
      listUrl={`/app/managers/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName=""
      {...props}
    />
  );
};

export const ManagerDetailPage = props => {
  return (
    <DetailPage
      title="Managers"
      apiCall={apiCall}
      listUrl={`/app/managers`}
      ItemDetail={ItemDetail}
      resourceName=""
      {...props}
    />
  );
};

export const ManagerCreatePage = props => (
  <CreatePage
    title="Managers"
    apiCall={apiCall}
    listUrl={`/app/managers`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
