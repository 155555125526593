import { Avatar, Menu, Modal } from 'antd';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { Link, useNavigate } from 'react-router-dom';

const UserMenu = ({ user }) => {
  let navigate = useNavigate();
  const { signOut } = useGoogleAuth();

  return (
    <Menu
      items={[
        {
          label: `${user.name || user.uid} (${user.email})`,
          key: 'account',
          icon: (
            <Avatar style={{ verticalAlign: 'middle' }}>
              {user.name ? user.name.charAt(0) : 'U'}
            </Avatar>
          ),
          children: [
            {
              key: 'logout',
              label: 'Logout',
              label: (
                <Link
                  to={''}
                  onClick={() =>
                    Modal.confirm({
                      title: 'Are you sure?',
                      onOk: () => {
                        signOut();

                        navigate('/login');
                      },
                    })
                  }
                >
                  Log Out
                </Link>
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default UserMenu;
