import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const DELETE_PAYMENT_TRANSACTION_API_ID =
  'DASHBOARD/DELETE_PAYMENT_TRANSACTION_API_ID';

const apiCall = enhancedMakeFetchAction(
  DELETE_PAYMENT_TRANSACTION_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/payment-transactions/${id}`,
    method: 'DELETE',
    notify: true,
  })
);

export default apiCall;
