import { Button, Card, Descriptions, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  const { id, name, email, phone, date_joined } = item || {};

  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-col gap-6 py-4 ">
        <Card
          title={<Title level={3}>Account Detail</Title>}
          extra={
            <Button type="primary">
              <Link to={`/app/administrators/${id}/edit`}>Edit</Link>
            </Button>
          }
        >
          <Descriptions column={1} bordered>
            <DescItem label="Name">{name}</DescItem>
            <DescItem label="Email">{email}</DescItem>
            <DescItem label="Phone">{phone}</DescItem>
            <DescItem label="Joined At">{formatDateTime(date_joined)}</DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
