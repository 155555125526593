import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import { orderStatusColors, orderStatusLabels } from 'app/constants/order';
import DataTable from 'common/ui/DataTable';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import OrderItemDataTable from './OrderItemDataTable'
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Card
        title={<Title level={3}>Order Detail</Title>}
        extra={
          <Space>
            <Button disabled={item.status == 1002 || item.status == 1003}>
              <Link to={`/app/orders/${item.id}/edit`}>Edit</Link>
            </Button>
            <Button>
              <Link to={`/app/orders/${item.id}/invoice`}>View invoice</Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Status">
            <Tag color={orderStatusColors[item.status]}>
              {orderStatusLabels[item.status]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Customer">
            {item.user && (
              <Link to={`/app/users/${item.user.id}`}>{item.user.name}</Link>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            {item.billing_address}
          </Descriptions.Item>

          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.customer_email}
          </Descriptions.Item>

          <Descriptions.Item label="Shipping Address">
            {item.shipping_address}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Contact">
            {item.shipping_name}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Phone Number">
            {item.shipping_phone_number}
          </Descriptions.Item>

          <Descriptions.Item label="Invoice No.">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Date">{item.date}</Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Reference No.">
            {item.reference_number}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card title={<Title level={3}>Order Items</Title>} className="my-5">
        <OrderItemDataTable dataSource={item.order_items} order={item}/>
      </Card>

      <Card className="my-5">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Personal Notes">
            {item.notes_admin}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
