import { Select, Spin } from 'antd';
import { getOr } from 'lodash/fp';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

const DebounceSelect = ({
  apiCall,
  debounceTimeout = 800,
  displayFn,
  params = {},
  fieldNames = {},
  onSelect,
  ...props
}) => {
  const [search, setSearch] = useState();

  const { data, isLoading } = useQuery({
    queryKey: [apiCall.queryKey, { ...params, search }],
    queryFn: () => apiCall.queryFn({ ...params, search }),
  });

  const options = getOr([], 'items')(data);

  const debounceSearch = useMemo(() => debounce(setSearch, debounceTimeout), [
    setSearch,
    debounceTimeout,
  ]);

  return (
    <Select
      showSearch={true}
      onSearch={debounceSearch}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      options={displayFn ? displayFn(options) : options}
      allowClear
      onSelect={(value, option) => {
        console.log('selected', value, option);
        onSelect && onSelect(value, option);
        setSearch('');
      }}
      fieldNames={{
        label: 'label',
        value: 'value',
        options: 'options',
        groupLabel: 'label',
        ...fieldNames,
      }}
      {...props}
    />
  );
};

export default DebounceSelect;
