import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const FETCH_INVENTORY_TRACKING_DELETE_API_ID =
  'DASHBOARD/FETCH_INVENTORY_TRACKING_DELETE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INVENTORY_TRACKING_DELETE_API_ID,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/inventory-trackings/${id}`,
    method: 'DELETE',
  })
);

export default apiCall;
