import apiCall from 'app/apiCalls/product';
import variantApiCall from 'app/apiCalls/productVariant';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator, variantColumns } from './config';
import ItemDetail from './detail';

export const ProductListPage = (props, { params }) => (
  <ListPage
    searchOptions={{ tooltip: "Enter product name" }}
    title="All Products"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    createUrl={`/app/products/create`}
    {...props}
  />
);

export const ProductEditPage = props => (
  <EditPage
    title="Product"
    apiCall={apiCall}
    listUrl={`/app/products`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const ProductDetailPage = props => {
  const { id } = useParams();

  return (
    <>
      <DetailPage
        title="Product Detail"
        apiCall={apiCall}
        listUrl={`/app/products`}
        ItemDetail={ItemDetail}
        resourceName=""
        {...props}
      />
      <div className="mt-4">
        <ListPage
          showBreadscrumb={false}
          createUrl={`/app/products/${id}/variants/create`}
          title="Product variants"
          apiCall={variantApiCall}
          columnGenerator={props => variantColumns({ id })}
          resourceName="items"
          params={{ product: id }}
          urlParamEnabled={false}
          {...props}
        />
      </div>
    </>
  );
};

export const ProductCreatePage = props => (
  <CreatePage
    title="Products"
    apiCall={apiCall}
    listUrl={`/app/products`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
