import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_PAYMENT_BOOK_CREATE_API_ID =
  'DASHBOARD/FETCH_PAYMENT_BOOK_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PAYMENT_BOOK_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/payment-accounts`,
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
