import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import { paymentMethods } from 'app/constants/paymentMethod';
import {
  statusColors as paymentStatusColors,
  statusLabels as paymentStatusLabels,
} from 'app/constants/status';
import DataTable from 'common/ui/DataTable';
import { Link, useParams } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';

const { Title } = Typography;

const ItemDetail = ({ item, reload }) => {
  const { id } = useParams();

  if (!item) {
    return null;
  }

  const { payment_type } = item;

  return (
    <>
      <Card
        title={<Title level={3}>PV Detail</Title>}
        extra={
          <Space>
            <Button disabled={item.status == 1002 || item.status == 1003}>
              <Link to={`/app/payment/transactions/${item.id}/edit`}>
                Update
              </Link>
            </Button>
            <Button>
              <Link to={`/app/payment/transactions/${item.id}/invoice`}>
                View invoice
              </Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item
            label={
              item.payment_type === 'inflow'
                ? 'Pay To Account'
                : 'Pay From Account'
            }
          >
            {item.payment_account ? item.payment_account.name : null}
          </Descriptions.Item>
          <Descriptions.Item
            label={item.payment_type === 'inflow' ? 'Pay From' : 'Pay To'}
          >
            {item.user ? item.user.name : null}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {item.payment_type === 'inflow' ? 'Money In' : 'Make Payment'}
          </Descriptions.Item>
          <Descriptions.Item label="Payment No.">{item.code}</Descriptions.Item>

          <Descriptions.Item label="Date">
            {formatDate(item.date)}
          </Descriptions.Item>
          <Descriptions.Item label="Reference Number">
            {item.reference_number}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={paymentStatusColors[item.status]}>
              {paymentStatusLabels[item.status]}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card className="my-5" title={<Title level={3}>Items</Title>}>
        <DataTable
          rowKey="id"
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Payment Mode',
              dataIndex: 'payment_method',
              render: value => paymentMethods[value],
              align: 'center',
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
          ]}
          dataSource={item.tx_items || []}
          totalItems={item.tx_items?.length || 0}
          currentPage={1}
          defaultCurrent={1}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3"></div>
          <div className="">
            <p>Sub Total:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="">
            <p>{formatCurrency(item.sub_total)}</p>
            <p>{formatCurrency(item.discount)}</p>
            <p>{formatCurrency(item.rounding_adjustment)}</p>
            <p>{formatCurrency(item.amount)}</p>
          </div>
        </div>
      </Card>
      <Card className="my-5">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Personal Notes">
            {item.personal_notes}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
