import { Button, Card, Descriptions, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';

const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <>
      <Card
        title={<Title level={3}>Product Detail</Title>}
        extra={
          <Button type="primary">
            <Link to={`/app/products/${item.id}/edit`}>Edit</Link>
          </Button>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Category">
            {item.category && item.category.id ? (
              <Link to={`/app/categories/${item.category.id}/edit`}>
                {item.category.name}
              </Link>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="ID">{item.id}</Descriptions.Item>
          <Descriptions.Item label="Product ID">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Image">
            {item.image && <img width={100} src={item.image} />}
          </Descriptions.Item>
          <Descriptions.Item label="Name (English)">
            {item.name}
          </Descriptions.Item>
          <Descriptions.Item label="Name (Malay)">
            {item.name_ms}
          </Descriptions.Item>
          <Descriptions.Item label="Name (Chinense)">
            {item.name_zh}
          </Descriptions.Item>
          <Descriptions.Item label="Description (English)">
            {item.description}
          </Descriptions.Item>
          <Descriptions.Item label="Description (Malay)">
            {item.description_ms}
          </Descriptions.Item>
          <Descriptions.Item label="Description (Chinese)">
            {item.description_zh}
          </Descriptions.Item>
          <Descriptions.Item label="Unit">{item.unit}</Descriptions.Item>
          <Descriptions.Item label="Price">
            {formatCurrency(item.price)}
          </Descriptions.Item>
          <Descriptions.Item label="Is Featured?">
            {item.is_featured ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Ordering">
            {item.ordering}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Last Updated At">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
