import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

export const CREATE_ORDER_API_ID = 'DASHBOARD/CREATE_ORDER_API_ID';

const apiCall = enhancedMakeFetchAction(CREATE_ORDER_API_ID, ({ payload }) => ({
  endpoint: `${API_BASE_URL}/admin/v1/orders`,
  method: 'POST',
  body: payload,
}));

export default apiCall;
