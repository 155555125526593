import { Button, Card, Space, Typography } from 'antd';
import DataTable from 'common/ui/DataTable';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { companyInfo, accountTypes } from 'app/constants/invoice';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
const { Title } = Typography;

const InvoiceItemDetail = ({ item }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: item.code,
  });

  const isCashExpense = item.user?.account_type === accountTypes.PAYMENT;
  const expenseName = isCashExpense ? "Cash Expense" : item.user?.name;
  const expenseInfo = isCashExpense ? item.user?.code : item.billing_address;

  return (
    <>
      <Card
        title={<Title level={3}>Invoice {item.code}</Title>}
        extra={
          <Space>
            <Button>
              <Link to={`/app/expenses/${item.id}`}>View detail</Link>
            </Button>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices"
        ref={componentRef}
      >
        <div className="pl-10 pt-10">
          <div className="hidden print:!block line-clamp-2">
            <b className="uppercase">Expense Invoice</b>
          </div>
          <div className="m-auto">
            <div className="leading-3">
              <div>
                <p>
                  <strong>{expenseName?.toUpperCase()}</strong>
                </p>
                <p>{expenseInfo}</p>
                {
                  item.phone_number && (
                    <p className="leading-6">Phone: {item.phone_number}</p>
                  )
                }
              </div>
              <hr className="my-5" />
              <div>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <p>
                      <strong>{companyInfo.name}</strong>
                    </p>
                    <p>{companyInfo.address1}</p>
                    <p>{companyInfo.address2}</p>
                    <p>{companyInfo.address3}</p>
                  </div>
                  <div>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <p>
                          <b>Invoice No.:</b>
                        </p>
                        <p>Date:</p>
                        <p>Reference No.:</p>
                        <p>Branch:</p>
                      </div>
                      <div className="col-span-2">
                        <p>
                          <b>{item.code}</b>
                        </p>
                        <p>{formatDate(item.created)}</p>
                        <p>{item.reference_number || "-"}</p>
                        <p>{item.branch ? item.branch.name : '-'}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 my-2">
                  <div>
                    <p>Phone</p>
                    <p>Email</p>
                    <p>TIN</p>
                  </div>
                  <div className="col-span-2">
                    <p>: {companyInfo.phone}</p>
                    <p>: {companyInfo.email}</p>
                    <p>: {companyInfo.tax_number}</p>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Particular',
                      dataIndex: 'particular',
                    },
                    {
                      title: 'Quantity',
                      dataIndex: 'quantity',
                      align: 'right',
                    },
                    {
                      title: 'Price',
                      dataIndex: 'price',
                      align: 'right',
                      render: value => formatCurrency(value),
                    },
                    {
                      title: 'Amount',
                      dataIndex: 'amount',
                      align: 'right',
                      render: value => formatCurrency(value),
                    },
                    {
                      title: 'Tax',
                      dataIndex: 'tax',
                      align: 'right',
                      render: value => formatCurrency(value),
                    },
                  ]}
                  dataSource={item.expense_items}
                  totalItems={item.expense_items.length}
                  currentPage={1}
                  defaultCurrent={1}
                />
              </div>
            </div>
            <div className="mt-10">
              <div className="">
                <hr className="my-5" />
                <div className="flex gap-16 justify-end">
                  <div className="">
                    <p>Sub Total:</p>
                    <p>Discount Given:</p>
                    <p>Rounding Adjustment:</p>
                    <p>Total Amount:</p>
                  </div>
                  <div className="">
                    <p>{formatCurrency(item.sub_total)}</p>
                    <p>{formatCurrency(item.discount)}</p>
                    <p>{formatCurrency(item.rounding_adjustment)}</p>
                    <p>{formatCurrency(item.amount)}</p>
                  </div>
                </div>
              </div>
              <div>
                {item.notes && (
                  <div className="my-5">
                    <p>
                      <b>Notes: </b>
                    </p>
                    <ol>
                      <div>{item.notes}</div>
                    </ol>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default InvoiceItemDetail;
