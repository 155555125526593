import { useEffect, useState } from 'react';
import { deleteToken, getToken, getUser } from 'utils/auth';

export default function useAdminAuth() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(async () => {
    const token = getToken();
    const user = getUser();
    setToken(token);
    setUser(user);

    setAuthenticated(token && user && user.email);

    setLoading(false);
  }, []);

  const signOut = () => deleteToken();

  return {
    user,
    token,
    isAuthenticated,
    loading,
    signOut,
  };
}
