export function getItemLinkPrefix(itemType) {
  switch (itemType) {
    case 'expense':
      return '/app/expenses';
    case 'sales':
      return '/app/orders';
    case 'purchase':
      return '/app/purchase-orders';
    case 'payment':
      return '/app/payment/transactions';
  }
}
