import { enhancedMakeFetchAction } from 'common/reduxutils';
import { API_BASE_URL } from 'config/env';

const FETCH_CUSTOMER_EDIT_API = 'DASHBOARD/FETCH_CUSTOMER_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_CUSTOMER_EDIT_API,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/admin/v1/customers/${id}`,
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
