import { Divider } from 'antd';
import MetaDecorator from 'components/MetaDecorator';
import { SITE_NAME } from 'config/env';

const Homepage = ({ name }) => {
  return (
    <>
      <MetaDecorator title={'Home'} />
      <h1>Hi {name} 👋</h1>
      <p>Welcome to {SITE_NAME}.</p>
      <div>
        <>
          <Divider orientation="left"></Divider>
        </>
      </div>
    </>
  );
};

export default Homepage;
